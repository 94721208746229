<template>
  <div class="zxfwxq">
    <div class="header">
      <div class="h1">黄骅市综合服务平台</div>
      <div class="h2">全面・便捷・专业 助力企业成长</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/zxpx' }">在线培训</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zxpxlist' }">视频课程</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zxpxxq' }">视频课程详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bottom">
        <div class="flex">
          <div class="flex-1">
            <div>
              <div class="biaoti">{{datas.title}}</div>
              <video id="videoPlay" v-if="datas.file" autoplay muted loop class="video" controls="controls"  :poster="datas.cover">
                <source   :src="datas.file" type="video/mp4">
              </video>
            </div>
            <div>
              <div class="zjjs">课程介绍</div>
              <div class="people" v-html="datas.content">
              </div>
            </div>
          </div>
<!--          <div class="rights">-->
<!--            <div>-->
<!--              课程目录-->
<!--            </div>-->
<!--            <div class="rcontent">-->
<!--              <el-collapse accordion v-model="activeNames">-->
<!--                <el-collapse-item name="1">-->
<!--                  <template slot="title">-->
<!--                    第1节 合同中知产产权条款的探讨-->
<!--                  </template>-->
<!--                  <div>1. 合同中知产产权条款的探讨；</div>-->
<!--                  <div>2. 合同中知识产权条款的探 讨（二）</div>-->
<!--                </el-collapse-item>-->
<!--                <el-collapse-item name="2">-->
<!--                  <template slot="title">-->
<!--                    第1节 合同中知产产权条款的探讨-->
<!--                  </template>-->
<!--                  <div>1. 合同中知产产权条款的探讨；</div>-->
<!--                  <div>2. 合同中知识产权条款的探 讨（二）</div>-->
<!--                </el-collapse-item>-->
<!--              </el-collapse>-->
<!--            </div>-->
<!--          </div>-->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {onlinedetail} from "../../request/moudle/zxpx";

export default {
  name: "gonggaoxq",
  data(){
    return {
      datas:{},
      activeNames:['1','2']
    }
  },
  created() {
    this.loadxq()
  },
  mounted() {
    document.getElementById("videoPlay").src = datas.file; //url为你需要播放的视频地址
  },
  methods:{
    go(){
      this.$router.push({
        path:'/zxfwlist'
      })
    },
    loadxq(){
      this.$api.zxpx.onlinedetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-collapse-item__header{
  background-color: #F1F2F3;
}
/deep/ .el-collapse-item__wrap{
  background-color: #F1F2F3;
}
  .zxfwxq{
     background: #F5F5F5;
    .header{
      height: 180px;
      background: url("../../assets/banner/zxpx.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .bottom{
        margin-top: 25px;
        background: #fff;
        padding: 25px;
        .biaoti{
          font-size: 16px;
          font-weight: 550;
        }
        .video{
          width: 884px;
          height: 498px;
          margin-top: 10px;
          background: #E3E5E7;
        }
        .rights{
          width: 295px;
          margin-left: 15px;
        }
        .rcontent{
          background: #F1F2F3;
          padding: 10px;
          margin-top: 15px;
        }
        .zjjs{
          width: 70px;
            font-size: 16px;
          font-weight: 550;
          line-height: 30px;
          margin-top: 30px;
          padding: 0 10px;
          background: #0078F2;
          color: #fff;
        }
        .people{
          padding: 0 30px;
          line-height: 25px;
          margin-top: 15px;
        }
      }
    }
  }
</style>